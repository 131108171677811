
.TestColumn-content{
  position: relative;
  .item{
    &::v-deep .el-badge__content{
      background: #E84932;
      border-radius: 8px;
      height: 16px;

    }
  }
  .TestColumn-title{
    cursor: pointer;
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    -webkit-flex-direction: column;
    align-items: center;
    -webkit-align-items: center;
    width: 48px;
    border-radius: 8px;
    font-size: 12px;
    .TestColumn-Icon{
      display: inline-block;
      height: 32px;
      line-height: 32px;
      -webkit-text-fill-color: #E84932;
      color: #E84932;
      cursor: pointer;
      i{
        font-size: 32px;
      }
    }
  }
  .TestColumn-dialog{
    z-index: 999;
    min-width: 220px;
    position: absolute;
    right: -4px;
    top: 54px;
    text-align: left;
    color: #333;
    .TestColumn-dialog-top{
      height: 30px;
      line-height: 30px;
      font-size: 14px;
      color: #333333;
      padding: 0 16px;
      background: #E7F6EF;
      font-weight: 500;
      position: relative;
      border: 1px solid #DCDCDC;
      .triangle{
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 6px solid #DCDCDC;
        position: absolute;
        right: 22px;
        top: -6px;
      }
    }
    .TestColumn-dialog-body{
      border: 1px solid #D2D2D2;
      border-top: none;
      background-color: #fff;
      .TestColumn-dialog-num{
        font-size: 14px;
        color: #666666;
        padding: 0px 14px;
        line-height: 36px;
        height: 36px;
        /* em {*/
        /*    font-style: normal;*/
        /*    color: #409eff;*/
        /*}*/
      }
      .TestColumn-dialog-type{
        height: 36px;
        line-height: 36px;
        font-size: 14px;
        padding: 0px 10px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        .TestColumn-dialog-title{
          color: #333333;
          font-size: 14px;
          font-weight: 500;
        }
        .TestColumn-dialog-del{
          font-size: 14px;
          color: #FF2000;
          cursor: pointer;
        }
      }
      .TestColumn-dialog-add {
        display: -webkit-box;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        padding: 20px;
        font-size: 14px;
      }
    }
    .TestColumn-dialog-foot{
      border-top: 1px solid #DCDCDC;
      display: -webkit-box;
      display: flex;
      -webkit-box-pack: start;
      justify-content: flex-start;
      -webkit-box-align: center;
      align-items: center;
      font-size: 14px;
      /*padding: 0 10px;*/
      height: 32px;
      background: #fff;
      .TestColumn-dialog-foot-left{
        display: -webkit-box;
        display: flex;
        -webkit-box-flex: 1;
        flex: 1;
        width: 1%;
        justify-content: center;
        span{
          color: #999999;
          cursor: pointer;
          font-size: 14px;
        }
      }
      .preview-btn{
        padding: 6px 4px;
      }
    }

  }
}
